
.hotTopic-container {
    background: #fff;
    padding-top: 20px;
}
::v-deep .el-table__expanded-cell {
    padding: 0;
    background: #F0F0F0;
    position: relative;
    height: 222px;
    width: 100%;
    &:hover {
        background-color: #F0F0F0!important;
    }
}
.hotTopic-carousel-box {
    width: 430px;
    position: relative;
    .hotTopic-carousel {
        width: 300px;
        .item {
            & > a {
                color: #999;
                display: block;
                .cover {
                    width: 80px;
                    height: 96px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    background: #F1F0FE;
                    border-radius: 6px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .text-overflow-2 {
                    margin: 8px 0 0;
                }
            }
        }
    }
    .topic-swiper-button-prev, .topic-swiper-button-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        outline: none;
        z-index: 9;
        .iconfont {
            font-size: 16px;
            display: block;
            color: #444;
            transition: all .3s;
        }
        &:hover {
            .iconfont {
                color: #685EF3;
            }
        }
    }
    .topic-swiper-button-prev {
        left: 0;
    }
    .topic-swiper-button-next {
        right: 0;
    }
    .topic-swiper-button-disabled {
        opacity: 0;
        cursor: auto;
    }
}
.listTable {
    width: 100%;
}
.hot-bar {
    color: #F73657;
    .iconfont {
        font-size: 12px;
        margin-right: 8px;
    }
}
::v-deep .table-header-cell {
    &:first-child {
        padding-left: 60px;
    }
}
.search-topic {
    padding: 0 20px 12px 0;
    text-align: right;
    .el-input {
        ::v-deep .el-input__inner {
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
            border-color: #594FEE;
        }
        ::v-deep .el-input__icon {
            line-height: 30px;
        }
    }
}
